body,html{
    overflow:hidden;
}
.collection_con{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f9f9f9;
    position: relative;
    .collection_cover{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .collection_head{
        height: 100px;
        display: flex;
        align-items: center;
        padding:0 24px;
        background-color: #4d6bfe;
        margin-bottom: 24px;
        .collection_logo{
            cursor: pointer;
            min-width: 120px;
            display: flex;
            align-items: center;
            font-size: 18px;
            margin-right: 54px;
            color: #fff;
            img{
                width: 40px;
                margin-right: 8px;
            }
        }
        .collection_list{
            flex:1;
            display: flex;
            overflow-x: scroll;
            height: 100%;
            align-items: center;
            margin-right: 30px;
            .dropdown_box{
                flex: 1;
                text-align: right;
            }
            .ant-dropdown-trigger{
                color: #fff;
                font-weight: bold;
            }
            >ul{
                position: absolute;
                padding-top: 4px;
                top: 10px;
                left: 180px;
                width: calc(100% - 180px);
                overflow: scroll;
                height: 200px;
                // 
                display: flex;
                li{
                    display: flex;
                    flex-direction: column;
                    min-width: 120px;
                    justify-content: center;
                    align-items: center;
    
                    padding: 14px;
                    height: 70px;
                    border-radius: 12px;
                    box-sizing: border-box;
                    background-color: #fff;
                    margin-right: 14px;
                    cursor: pointer;
                    line-height: 22px;
                    transition: all .3s ease;
                    position: relative;
                    z-index: 1000;
                    img{
                        width: 40px;
                        border-radius: 50%;
                        // margin-right: 8px;
                    }
                    p{
                        flex:1;
                        font-size: 14px;
                        font-weight: bold;
                        span{
                            font-weight: 400;
                            max-width: 120px;
                            display: block;
                            white-space:nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 12px;
                        }
                    }
                    // 气泡框
                    .bubble-box{
                        position: absolute;
                        top:70px;
                        left:0;
                        display: none;
                        .bubble-container {
                            position: relative;
                            background-color: #191919; /* 气泡框背景颜色 */
                            color: #fff; /* 文字颜色 */
                            border-radius: 10px; /* 圆角边框 */
                            padding: 10px; /* 内边距 */
                            margin: 10px; /* 外边距，可根据需要调整 */
                            z-index: 1000;
                            span{
                                font-size: 12px;
                            }
                            .triangle {
                                position: absolute;
                                width: 0;
                                height: 0;
                                border-left: 10px solid transparent; /* 左边透明 */
                                border-right: 10px solid transparent; /* 右边透明 */
                                border-bottom: 10px solid #191919; /* 底边为气泡框背景色 */
                                top: -8px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
                li:hover {
                    margin-top:-3px;
                    box-shadow: 0 26px 40px -24px rgba(0,36,100,.3);
                    .bubble-box{
                        display: block;
                    }
                    p{
                        color:#4449b4;
                    }
                }
            }
            >ul::-webkit-scrollbar {
                height: 0;
            }
        }
        /* 使用伪元素隐藏滚动条（适用于WebKit浏览器，如Chrome和Safari） */
        .collection_list {
            overflow: auto;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
        
        /* 对于WebKit浏览器，你也可以使用 ::-webkit-scrollbar 来隐藏滚动条 */
        .collection_list::-webkit-scrollbar {
            display: none; /* 隐藏滚动条 */
        }
        .cooperate{
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
        }
    }
    .collection_body{
        flex:1;
        display: flex;
        .collection_cho{
            width: 200px;
            overflow: scroll;
            padding-left: 12px;
            .collection_cho_item{
                cursor: pointer;
                width: 150px;
                display: flex;
                align-items: center;
                // background-color: #fff;
                margin-bottom: 16px;
                border-radius: 12px;
                padding: 6px 14px;
                img{
                    margin-right: 12px;
                    width: 34px;
                    border-radius: 50%;
                }
            }
            .active_item{
                background-color: rgb(67, 96, 253);
                color: #fff;
                font-weight: 500;
            }
            .collection_cho_item:last-child{
                margin-bottom: 0;
            }
        }
        iframe {
            position: fixed;
            // background: #000;
            border: none;
            top: 100px; 
            right: 0;
            bottom: 0; 
            left: 200px;
            width: calc(100% - 200px);
            height: calc(100% - 100px);
        }
    }
}

.slider_box{
    // max-width: 1400px;
    .slick-track{
        margin:0;
    }
    .slider_chi{
        cursor: pointer;
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 180px;
        height: 54px;
        border-radius: 30px;
        background-color: rgb(137, 155, 252);
        color: rgb(247, 248, 248);
        box-sizing: border-box;
        // padding-left: 12px;
        // padding-top:6px;
        position: relative;
        .fun_box{
            position: absolute;
            top: -8px;
            right: 0;
            font-size: 11px;
            padding: 1px 5px;
            width: 24px;
            background-color: rgb(183, 194, 255);
            color: rgb(68, 96, 253);
            border-radius: 50%;
            font-weight: 500;
            text-align: center;
            // background: linear-gradient(270deg,rgba(198,240,230,.16),rgba(198,240,230,.4));
            // color: #25ab46;
            // border-radius: 0 0 0 12px;
        }
        img{
            width: 30px;
            margin-right: 8px;
        }
        div{
            p{
                flex:1;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 2px;
            }
            p:last-child{
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
    .spe_box{
        background-color: rgb(93, 118, 253);
        color: rgb(212, 217, 246);
    }
}
.slick-prev:before, .slick-next:before{
    color: #436ff6!important;
    font-size: 24px!important;
}
@primary-color: #1DA57A;